<template>
    <div>
        <div class="car-find-sub-hd">
            {{ localeLang.SUBSCIPTION_PAYMENT.LABLES.PAYWAY.SELECTION }}
        </div>
        <form @submit.prevent="payWithPayway" ref="paywayForm" method="POST" action="/subscription/payments/payway">
            <input type="hidden" name="_token" id="csrf-token" v-model="request.token" />
            <input type="hidden" name="slug" v-model="request.slug">
            <input type="hidden" name="payment_as" v-model="request.payment_as">
            <input type="hidden" name="payment_with" v-model="request.payment_with">
            <input type="hidden" name="plan_id" v-model="request.plan_id">
            <input type="hidden" name="discount_id" v-model="request.discount_id">
            <input type="hidden" name="sub_total" v-model="request.sub_total">
            <input type="hidden" name="discount_total" v-model="request.discount_total">
            <input type="hidden" name="total" v-model="request.total">
            <div class="form-group">
                <div class="custom-radio radio-inline form-group">
                    <div class="radio-inner">
                        <input type="radio" name="payment_option" id="payment_option_cards" class="payment_option" v-model="request.payment_option" value="cards"> 
                        <label for="payment_option_cards">
                            <img src="/frontend/images/ic_generic_1x.png">
                            <span style="margin-left: 16px; float: right; position: absolute;">
                                <span>  {{ localeLang.SUBSCIPTION_PAYMENT.LABLES.PAYWAY.CREDIT_CARD_LEVEL }} </span><br/>
                                <img src="/frontend/images/A-3Card_1x.png" style="margin-top: 5px;">
                            </span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="custom-radio radio-inline form-group">
                    <div class="radio-inner">
                        <input type="radio" name="payment_option" id="payment_option_abapay" class="payment_option" v-model="request.payment_option"  value="abapay"> 
                        <label for="payment_option_abapay">
                            <img src="/frontend/images/ic_ABA PAY_1x.png">
                            <span style="margin-left: 16px; float: right; position: absolute;">
                                <span>{{ localeLang.SUBSCIPTION_PAYMENT.LABLES.PAYWAY.ABA_PAY_LEVEL }}</span><br/>
                                <span style="margin-top: 5px;">{{ localeLang.SUBSCIPTION_PAYMENT.LABLES.PAYWAY.ABA_PAY_SUB_LEVEL }}</span>
                            </span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="form-group wd1">
                <button type="submit" class="btn cmn-btn blue-btn">
                    {{ localeLang.SUBSCIPTION_PAYMENT.ACTIONS.SUBSCRIBE }}
                </button>
            </div>
        </form>
    </div>
</template>
<script>
    import VeeValidate from 'vee-validate';
    import FormMixin from '../../../mixins/FormMixin';
    import CommonMixin from '../../../mixins/CommonMixin';
    export default {
        name:"user-subscription-payway-component",
        mixins: [CommonMixin, FormMixin],
        props:[
            "plan",
            "discount",
            "subTotal",
            "discountTotal",
            "total",
            "paymentAs",
            "vehicleSlug",
            "localeLang"
        ],
        components:{
            VeeValidate: VeeValidate
        },
        data() {
            return {
                loading : false,
                request:{
                    token: window.token.content,
                    slug: this.vehicleSlug,
                    payment_as: this.paymentAs,
                    payment_with: "payway",
                    plan_id: this.plan.id ? this.plan.id : '',
                    discount_id: this.discount.id ? this.discount.id : '',
                    sub_total: this.subTotal,
                    discount_total: this.discountTotal,
                    total: this.total,
                    payment_option: "cards"
                }
            }
        },

        watch: {
            paymentAs : function(n, o){
                this.request.payment_as = n;
            },
            plan : function(n, o){
                this.request.plan_id = n.id;
            },
            discount : function(n, o){
                this.request.discount_id = n.id;
            },
            subTotal : function(n, o){
                this.request.sub_total = n;
            },
            discountTotal : function(n, o){
                this.request.discount_total = n;
            },
            total : function(n, o){
                this.request.total = n;
            },
        },
        methods:{
            payWithPayway(){
                window.$(this.$refs.paywayForm).submit();
            }
        }
    }
</script>
