import moment from "moment";
export default {
    data() {
        return {
            // Development
           // baseUrl: "http://localhost:8000",

            // Staging
            //baseUrl: "https://demo.carmarketkh.com",

            // Prodduction
            baseUrl: "https://carmarket.com.kh",

            avatar_default: "/frontend/images/defaults/avatar_no_image.png",

        }
    },
    filters: {
        capitalize: function(str) {
            if (!str) return ''
            str = str.toString().toLowerCase();
            return str.charAt(0).toUpperCase() + str.slice(1);
        },
        uppercase: function(str) {
            if (!str) return ''
            str = str.toString();
            return str.toUpperCase();
        },
        lowercase: function(str) {
            if (!str) return ''
            str = str.toString();
            return str.toLowerCase();
        },
        str_limit: function(str, limit) {
            if (!str) return ''
            str = str.toString();
            if (str.length > limit) {
                str = str.substring(0, limit) + "..."
            }
            return str;
        },
        str_slug(str) {
            if (!str) return ''
            str = str.toString();
            str = str.replace(/\s+/g, '-').toLowerCase();
            return str;
        },
        humanDate: function(date) {
            return moment(String(date), 'YYYY-MM-DD').format('DD MMM YYYY');
        },
        toCurrency(value) {
            var formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0
            });
            return formatter.format(value);
        },
        toNumber(value) {
            return new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(value)
        },
        toMaskedPrice(amount, maskDigits) {

            if(amount){
                let formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0
                });

                let formatedPrice =  formatter.format(amount);

                if((formatedPrice.substring(0, (maskDigits+1)).match(/,/g) || []).length){
                	maskDigits = maskDigits + 1;
                }

                let displabalePrice = formatedPrice.substring(0, (maskDigits+1));
            	let hiddenPrice = formatedPrice.substring((maskDigits+1));

            	hiddenPrice = hiddenPrice.replace(/[0-9]/g, "x");

            	return (displabalePrice+hiddenPrice);
            }
            return '';
        }
    }
}
