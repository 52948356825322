<template>
    <div>
        <div class="create-ads-inner global-form create-car-ads-form">
            <div class="step-block-outer">
                <div class="step-box1 step-5">
                    <div class="payment-block-outer">
                        <div class="table-responsive custom-table">
                            <table class="table table-bordered">
                                <thead class="thead-dark text-uppercase">
                                    <tr>
                                        <th width="25%">
                                            {{
                                                localeLang.SUBSCIPTION_PAYMENT
                                                    .LABLES.PLAN_TYPE
                                            }}
                                        </th>
                                        <th width="25%">
                                            {{
                                                localeLang.SUBSCIPTION_PAYMENT
                                                    .LABLES.DURATION
                                            }}
                                        </th>
                                        <th width="25%">
                                            {{
                                                localeLang.SUBSCIPTION_PAYMENT
                                                    .LABLES.FEATURES
                                            }}
                                        </th>
                                        <th width="25%" class="text-right">
                                            {{
                                                localeLang.SUBSCIPTION_PAYMENT
                                                    .LABLES.PRICE
                                            }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="form-group">
                                                <div class="select-outer">
                                                    <select
                                                        class="form-control"
                                                        v-model="request.plan"
                                                    >
                                                        <option
                                                            v-for="(subscription,
                                                            index) in subscriptions"
                                                            :value="
                                                                subscription.id
                                                            "
                                                            >{{
                                                                subscription.title
                                                            }}</option
                                                        >
                                                    </select>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <span
                                                v-if="
                                                    plan.plan_interval == 'day'
                                                "
                                            >
                                                {{
                                                    localeLang.SUBSCIPTION_PLANS
                                                        .LABELS.VEHICLE_A_DAY
                                                }}
                                            </span>
                                            <span
                                                v-else-if="
                                                    plan.plan_interval == 'week'
                                                "
                                            >
                                                {{
                                                    localeLang.SUBSCIPTION_PLANS
                                                        .LABELS.VEHICLE_A_WEEK
                                                }}
                                            </span>
                                            <span
                                                v-else-if="
                                                    plan.plan_interval ==
                                                        'month'
                                                "
                                            >
                                                {{
                                                    localeLang.SUBSCIPTION_PLANS
                                                        .LABELS.VEHICLE_A_MONTH
                                                }}
                                            </span>
                                            <span
                                                v-else-if="
                                                    plan.plan_interval == 'year'
                                                "
                                            >
                                                {{
                                                    localeLang.SUBSCIPTION_PLANS
                                                        .LABELS.VEHICLE_AN_YEAR
                                                }}
                                            </span>
                                        </td>
                                        <td>
                                            <ul>
                                                <li
                                                    v-if="
                                                        plan.total_listing_count
                                                    "
                                                >
                                                    {{
                                                        plan.total_listing_count
                                                    }}
                                                    {{
                                                        localeLang
                                                            .SUBSCIPTION_PLANS
                                                            .LABELS
                                                            .VEHICLE_IN_LISTING
                                                    }}
                                                </li>
                                                <li
                                                    v-if="
                                                        plan.total_featured_count
                                                    "
                                                >
                                                    {{
                                                        plan.total_featured_count
                                                    }}
                                                    {{
                                                        localeLang
                                                            .SUBSCIPTION_PLANS
                                                            .LABELS
                                                            .VEHICLE_IN_FEATURED
                                                    }}
                                                </li>
                                                <li
                                                    v-if="
                                                        plan.total_on_sale_count
                                                    "
                                                >
                                                    {{
                                                        plan.total_on_sale_count
                                                    }}
                                                    {{
                                                        localeLang
                                                            .SUBSCIPTION_PLANS
                                                            .LABELS
                                                            .VEHICLE_IN_ONSALE
                                                    }}
                                                </li>
                                                <li
                                                    v-if="
                                                        plan.total_until_sold_count
                                                    "
                                                >
                                                    {{
                                                        plan.total_until_sold_count
                                                    }}
                                                    {{
                                                        localeLang
                                                            .SUBSCIPTION_PLANS
                                                            .LABELS
                                                            .VEHICLE_IN_UNTILSOLD
                                                    }}
                                                </li>
                                            </ul>
                                        </td>
                                        <td class="text-right">
                                            {{ plan.price | toCurrency }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            colspan="2"
                                            align="right"
                                            class="text-right"
                                        >
                                            <div
                                                class="form-group"
                                                v-if="plan.plan_type === 'Paid'"
                                            >
                                                <div class="row">
                                                    <div class="col-lg-4">
                                                        <input
                                                            type="text"
                                                            :placeholder="
                                                                localeLang
                                                                    .SUBSCIPTION_PAYMENT
                                                                    .PLACEHOLDERS
                                                                    .ENTER_COUPON
                                                            "
                                                            class="form-control"
                                                            v-model="
                                                                request.coupon
                                                            "
                                                        />
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="row">
                                                            <div
                                                                class="col-6 mbl-gap"
                                                            >
                                                                <button
                                                                    type="button"
                                                                    class="btn cmn-btn blue-btn"
                                                                    disabled
                                                                    v-if="
                                                                        loading
                                                                    "
                                                                >
                                                                    <span
                                                                        class="spinner-border spinner-border-sm text-light"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                    <span>{{
                                                                        localeLang
                                                                            .SUBSCIPTION_PAYMENT
                                                                            .ACTIONS
                                                                            .APPLY
                                                                    }}</span>
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    class="btn cmn-btn blue-btn"
                                                                    v-on:click="
                                                                        applyCoupon
                                                                    "
                                                                    v-else
                                                                >
                                                                    {{
                                                                        localeLang
                                                                            .SUBSCIPTION_PAYMENT
                                                                            .ACTIONS
                                                                            .APPLY
                                                                    }}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td
                                            colspan="2"
                                            align="right"
                                            class="text-right"
                                        >
                                            <ul>
                                                <li>
                                                    <strong>
                                                        {{
                                                            localeLang
                                                                .SUBSCIPTION_PAYMENT
                                                                .LABLES
                                                                .SUB_TOTAL
                                                        }}:
                                                        {{
                                                            subTotal
                                                                | toCurrency
                                                        }}
                                                    </strong>
                                                </li>
                                                <li v-show="discountTotal > 0">
                                                    <strong>
                                                        {{
                                                            localeLang
                                                                .SUBSCIPTION_PAYMENT
                                                                .LABLES
                                                                .DISCOUNT_TOTAL
                                                        }}:
                                                        {{
                                                            discountTotal
                                                                | toCurrency
                                                        }}
                                                    </strong>
                                                </li>
                                                <li>
                                                    <strong>
                                                        {{
                                                            localeLang
                                                                .SUBSCIPTION_PAYMENT
                                                                .LABLES.TOTAL
                                                        }}:
                                                        {{ total | toCurrency }}
                                                    </strong>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-if="isPaid === true">
                            <div class="car-find-sub-hd">
                                {{
                                    localeLang.SUBSCIPTION_PAYMENT.LABLES
                                        .PAYMENT_METHOD_HEADING
                                }}
                            </div>
                            <div class="tab-block2">
                                <!-- Nav tabs -->
                                <ul class="nav nav-tabs">
                                    <li
                                        class="nav-item"
                                        v-show="request.payment_as === 'single'"
                                    >
                                        <a
                                            class="nav-link active"
                                            data-toggle="tab"
                                            href="#pay2"
                                        >
                                            <img
                                                src="/frontend/images/wing-icon.png"
                                                alt=""
                                            />
                                            {{
                                                localeLang.SUBSCIPTION_PAYMENT
                                                    .LABLES.WINGS.TITLE
                                            }}
                                            <i class="fa fa-chevron-right"></i>
                                        </a>
                                    </li>
                                    <li
                                        class="nav-item"
                                        v-show="request.payment_as === 'single'"
                                    >
                                        <a
                                            class="nav-link"
                                            data-toggle="tab"
                                            href="#pay3"
                                        >
                                            <!-- <img
                                                src="/frontend/images/payway-icon.png"
                                                alt=""
                                            /> -->
                                            {{
                                                localeLang.SUBSCIPTION_PAYMENT
                                                    .LABLES.PAYWAY.TITLE
                                            }}
                                            <i class="fa fa-chevron-right"></i>
                                        </a>
                                    </li>
                                </ul>
                                <!-- Tab panes -->
                                <div class="tab-content">
                                    <div class="tab-pane active" id="pay2">
                                        <!-- wing payment form component -->
                                        <user-subscription-wing-component
                                            :plan="plan"
                                            :discount="discount"
                                            :sub-total="subTotal"
                                            :discount-total="discountTotal"
                                            :total="total"
                                            :payment-as="request.payment_as"
                                            :vehicle-slug="vehicleSlug"
                                            :locale-lang="localeLang"
                                        >
                                        </user-subscription-wing-component>
                                        <!-- wings payment form component -->
                                    </div>
                                    <div class="tab-pane" id="pay3">
                                        <!-- payway payment form component -->
                                        <user-subscription-payway-component
                                            :plan="plan"
                                            :discount="discount"
                                            :sub-total="subTotal"
                                            :discount-total="discountTotal"
                                            :total="total"
                                            :payment-as="request.payment_as"
                                            :vehicle-slug="vehicleSlug"
                                            :locale-lang="localeLang"
                                        >
                                        </user-subscription-payway-component>
                                        <!-- payway payment form component -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="form-group wd1">
                                <button
                                    type="button"
                                    class="btn cmn-btn blue-btn"
                                    disabled
                                    v-if="loading"
                                >
                                    <span
                                        class="spinner-border spinner-border-sm text-danger"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    <span>{{
                                        localeLang.SUBSCIPTION_PAYMENT.ACTIONS
                                            .SUBSCRIBE
                                    }}</span>
                                </button>
                                <button
                                    type="button"
                                    class="btn cmn-btn blue-btn"
                                    v-on:click="subscribeToFreePlan()"
                                    v-else
                                >
                                    {{
                                        localeLang.SUBSCIPTION_PAYMENT.ACTIONS
                                            .SUBSCRIBE
                                    }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Custom Popup -->
        <div
            class="modal fade custom-popup vehicle-price-modal"
            id="vehicle-price-modal"
            ref="vehiclePriceModal"
        >
            <div
                class="modal-dialog modal-dialog-centered"
                style="width: 450px"
            >
                <div class="modal-content">
                    <div class="modal-body">
                        <button
                            data-dismiss="modal"
                            class="close"
                            type="button"
                        >
                            ×
                        </button>
                        <h2>
                            {{
                                localeLang.SUBSCIPTION_PAYMENT.LABLES
                                    .PRICE_UPDATE.TITLE1
                            }}
                            ${{ plan.max_price }}
                            {{
                                localeLang.SUBSCIPTION_PAYMENT.LABLES
                                    .PRICE_UPDATE.TITLE2
                            }}
                        </h2>
                        <form @submit.prevent="updateVehiclePrice">
                            <div class="form-group">
                                <label
                                    >{{
                                        localeLang.SUBSCIPTION_PAYMENT.LABLES
                                            .PRICE_UPDATE.SALE_PRICE
                                    }}<span class="required">*</span></label
                                >
                                <input
                                    type="text"
                                    :placeholder="
                                        localeLang.SUBSCIPTION_PAYMENT.LABLES
                                            .PRICE_UPDATE.SALE_PRICE
                                    "
                                    class="form-control"
                                    name="sale_price"
                                    :class="
                                        errors.has('sale_price')
                                            ? 'is-invalid'
                                            : ''
                                    "
                                    v-model="price_update.sale_price"
                                    v-validate="{
                                        required: true,
                                        numeric: true,
                                        min: 3,
                                        max: 7,
                                        min_value:
                                            request.discounted_price != null
                                                ? request.discounted_price
                                                : false,
                                        max_value:
                                            plan.plan_type === 'Free'
                                                ? plan.max_price
                                                : false
                                    }"
                                    data-vv-as="sale price"
                                />
                                <div
                                    class="invalid-feedback"
                                    v-if="errors.has('sale_price')"
                                >
                                    {{ errors.first("sale_price") }}
                                </div>
                            </div>
                            <div class="form-group" v-if="isDealer === 'true'">
                                <label
                                    >{{
                                        localeLang.SUBSCIPTION_PAYMENT.LABLES
                                            .PRICE_UPDATE.DISCOUNTED_PRICE
                                    }}<span class="required">*</span></label
                                >
                                <input
                                    type="text"
                                    :placeholder="
                                        localeLang.SUBSCIPTION_PAYMENT.LABLES
                                            .PRICE_UPDATE.DISCOUNTED_PRICE
                                    "
                                    class="form-control"
                                    name="discounted_price"
                                    :class="
                                        errors.has('discounted_price')
                                            ? 'is-invalid'
                                            : ''
                                    "
                                    v-model="price_update.discounted_price"
                                    v-validate="{
                                        numeric: true,
                                        min: 3,
                                        max: 7,
                                        max_value: request.sale_price
                                            ? request.sale_price
                                            : false
                                    }"
                                    data-vv-as="discounted price"
                                />
                                <div
                                    class="invalid-feedback"
                                    v-if="errors.has('discounted_price')"
                                >
                                    {{ errors.first("discounted_price") }}
                                </div>
                            </div>
                            <div class="form-group submit-form">
                                <button
                                    type="button"
                                    class="submit-btn"
                                    disabled
                                    v-if="loading"
                                >
                                    <span
                                        class="spinner-border spinner-border-sm text-light"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    <span>{{
                                        localeLang.SUBSCIPTION_PAYMENT.LABLES
                                            .PRICE_UPDATE.UPDATE_BUTTON
                                    }}</span>
                                </button>
                                <button
                                    type="submit"
                                    class="submit-btn"
                                    :disabled="errors.any()"
                                    v-else
                                >
                                    {{
                                        localeLang.SUBSCIPTION_PAYMENT.LABLES
                                            .PRICE_UPDATE.UPDATE_BUTTON
                                    }}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- Custom Popup -->
    </div>
</template>
<script>
import bootbox from "bootbox";
import FormMixin from "../../../mixins/FormMixin";
import CommonMixin from "../../../mixins/CommonMixin";
import VeeValidate, { Validator } from "vee-validate";
import mangeAdPriceDictionary from "../../../locale/kh/mangeAdPriceDictionary";
import UserSubscriptionWingComponent from "../../../components/user/subscription/UserSubscriptionWingComponent";
import UserSubscriptionPaywayComponent from "../../../components/user/subscription/UserSubscriptionPaywayComponent";
import UserSubscriptionStripeComponent from "../../../components/user/subscription/UserSubscriptionStripeComponent";

export default {
    name: "user-subscription-payment-component",
    props: [
        "stripeKey",
        "isDealer",
        "vehicleSlug",
        "vehiclePrice",
        "subscriptions",
        "paymentMethods",
        "selectedPlan",
        "localeLang"
    ],
    components: {
        VeeValidate: VeeValidate,
        UserSubscriptionWingComponent: UserSubscriptionWingComponent,
        UserSubscriptionPaywayComponent: UserSubscriptionPaywayComponent,
        UserSubscriptionStripeComponent: UserSubscriptionStripeComponent
    },
    mixins: [CommonMixin, FormMixin],
    data() {
        return {
            loading: false,
            discount: [],
            request: {
                plan: this.selectedPlan ? this.selectedPlan.id : "",
                coupon: null,
                payment_as: "single",
                payment_with: "stripe"
            },
            price_update: {
                sale_price: "",
                discount_price: ""
            }
        };
    },
    mounted() {
        if (this.localeName === "kh") {
            Validator.localize("kh", mangeAdPriceDictionary);
        }
    },
    computed: {
        plan: function() {
            let plan = "";
            if (
                this.subscriptions.some(item => item.id === this.request.plan)
            ) {
                let key = this.subscriptions.findIndex(
                    item => item.id === this.request.plan
                );
                plan = this.subscriptions[key];
            } else {
                plan = this.subscriptions[0];
            }
            this.request.plan = plan.id;

            return plan;
        },
        subTotal() {
            return this.plan.price;
        },
        discountTotal() {
            let amount = 0;
            if (Object.keys(this.discount).length) {
                if (this.discount.type === "fixed") {
                    amount = parseFloat(this.discount.discount);
                } else if (this.discount.type === "percentage") {
                    amount = parseFloat(
                        (this.discount.discount / 100) * this.subTotal
                    );
                }
            }
            if (amount > this.subTotal) {
                return this.subTotal;
            }
            return amount;
        },
        total() {
            return this.subTotal - this.discountTotal;
        },

        isPaid() {
            return this.plan.plan_type === "Paid" && this.total > 0
                ? true
                : false;
        }
    },
    watch: {
        plan: async function(n, o) {
            this.request.plan_id = n.id;
            let shouldInitModal = await this.shouldInitModal();
            if (shouldInitModal) {
                this.initPriceModal();
            }
        }
    },
    methods: {
        async shouldInitModal() {
            if (this.plan.plan_type === "Free") {
                if (this.vehicleSlug && this.vehiclePrice) {
                    if (this.vehiclePrice > this.plan.max_price) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        initPriceModal() {
            window
                .$(this.$refs.vehiclePriceModal)
                .modal({ backdrop: "static", keyboard: false });
        },
        async subscribeToFreePlan() {
            let shouldInitModal = await this.shouldInitModal();
            if (shouldInitModal) {
                this.initPriceModal();
                return false;
            }
            this.loading = true;
            axios
                .post("/api/subscription/subscribe-free-plan", {
                    plan_id: this.plan.id,
                    discount_id: this.discount.id,
                    sub_total: this.subTotal,
                    discount_total: this.discountTotal,
                    total: this.total,
                    slug: this.vehicleSlug
                })
                .then(response => {
                    this.loading = false;
                    window.location = response.data.url;
                 flash(response.data.message, response.data.status);
                })
                .catch(error => {
                    this.loading = false;
                });
        },
        updateVehiclePrice() {
            this.loading = true;
            this.$validator.validate().then(valid => {
                if (valid) {
                    axios
                        .post("/api/vehicle/manage/update-price", {
                            vehicle_slug: this.vehicleSlug,
                            sale_price: this.price_update.sale_price,
                            discounted_price: this.price_update.discounted_price
                        })
                        .then(response => {
                            this.loading = false;
                            if (response.data.status === "success") {
                                window
                                    .$(this.$refs.vehiclePriceModal)
                                    .modal("hide");
                                this.vehiclePrice = this.price_update.discounted_price;
                            }
                            flash(response.data.message, response.data.status);
                        })
                        .catch(error => {
                            this.loading = false;
                            if (error.hasOwnProperty("response")) {
                                this.setErrorsFromResponse(error.response.data);
                            } else {
                                console.log(error);
                            }
                        });
                } else {
                    this.loading = false;
                }
            });
        },
        applyCoupon() {
            if (this.request.coupon === null) return;
            this.loading = true;
            axios
                .post("/api/subscription/apply-coupon", {
                    coupon: this.request.coupon
                })
                .then(response => {
                    this.loading = false;
                    if (response.data.status === "success") {
                        this.discount = response.data.discount;
                        this.request.coupon = null;
                    } else {
                        this.discount = [];
                    }
                    flash(response.data.message, response.data.status);
                })
                .catch(error => {
                    this.loading = false;
                    this.discount = [];
                    this.request.coupon = null;
                });
        }
    }
};
</script>
