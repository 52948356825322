<template>
    <div>
        <div class="top-hd-block">
            <div class="container">
                <div class="title-sec">
                    <div class="row">
                        <div class="col-md-9">
                            <h1>{{ searchTitle }}</h1>
                            <div class="search-result-text">
                                <span>{{ localeLang.FILTER.REFINE_SEARCH }}</span>
                                <a href="javascript:;" v-on:click="resetFilters()">
                                    {{ localeLang.FILTER.CLEAR_SEARCH }}
                                </a>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="select-outer feture-select">
                                <select class="form-control select-2-field" v-model="vehicleFilters.sort_by" ref="sortByField">
                                    <option value="1">{{ localeLang.SORTING.FEATURED }}</option>
                                    <option value="2">{{ localeLang.SORTING.INSPECTED }}</option>
                                    <option value="3">{{ localeLang.SORTING.PRICE_HIGH_TO_LOW }}</option>
                                    <option value="4">{{ localeLang.SORTING.PRICE_LOW_TO_HIGH }}</option>
                                    <option value="5">{{ localeLang.SORTING.YEAR_MADE_HIGH_TO_LOW }}</option>
                                    <option value="6">{{ localeLang.SORTING.YEAR_MADE_LOW_TO_HIGH }}</option>
                                    <option value="7">{{ localeLang.SORTING.KM_HIGH_TO_LOW }}</option>
                                    <option value="8">{{ localeLang.SORTING.KM_LOW_TO_HIGH }}</option>
                                    <option value="9">{{ localeLang.SORTING.RECENTLY_ADDED }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="car-list-inner-sec pad0">
                <vehicle-compare-list-component
                    :comparing-vehicles="comparingVehicles"
                    @handle-remove-compare="removeCompare"
                    @handle-remove-all-compare="removeAllCompare"
                    :locale-lang="localeLang"
                >
                </vehicle-compare-list-component>
                <div class="row">
                    <div class="col-md-4 col-lg-3 left-bar-list">
                        <div class="car-list-form">
                            <form>
                                <div class="form-group">
                                    <h3>{{ localeLang.FILTER.HEADING_KEYWORD }}</h3>
                                    <div class="input-col">
                                        <input type="text" :placeholder="localeLang.FILTER.KEYWORD_PLACEHOLDER" class="form-control" v-model="vehicleFilters.keyword" v-on:keypress="filterByKeyword($event)" />
                                    </div>
                                   <div class="input-col">
                                        <div class="select-outer select-2-field">
                                            <select class="form-control" name="user" v-model="vehicleFilters.user" ref="userField">
                                                <option value="">{{ localeLang.FILTER.USER_PLACEHOLDER }}</option>
                                                <!-- <option v-for="(dsv, dsk) in dealerSeller" :value="dsk">{{ dsv }} </option> -->
                                                <option v-for="item in dealerSeller" :value="item.id">{{ item.name }} </option>
                                            </select>
                                        </div>
                                    </div>

                                </div>
                                <div class="form-group">
                                    <h3>{{ localeLang.FILTER.HEADING_MAKE_MODEL }}</h3>

                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <div class="input-col">
                                                    <div class="select-outer select-2-field">
                                                        <select class="form-control" name="type" v-model="vehicleFilters.type" ref="typeField">
                                                            <option value="">{{ localeLang.FILTER.AD_TYPE_PLACEHOLDER }}</option>
                                                            <option v-for="type in types" :value="type.slug">{{ type.name }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <div class="input-col">
                                                    <div class="select-outer select-2-field">
                                                        <select class="form-control" name="type" v-model="vehicleFilters.added" ref="addedByField">
                                                            <option value="">{{ localeLang.FILTER.ADDED_BY.TITLE }}</option>
                                                            <option v-for="(addedBy, key) in localeLang.FILTER.ADDED_BY.OPTIONS" :value="key">{{ addedBy }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <div class="input-col">
                                                    <div class="select-outer select-2-field">
                                                        <select class="form-control" name="make" v-model="vehicleFilters.make" ref="makeField">
                                                            <option value="">{{ localeLang.FILTER.MAKE_PLACEHOLDER }}</option>
                                                            <option v-for="make in makes" :value="make.slug">{{ make.name }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <div class="input-col">
                                                    <div class="select-outer select-2-field">
                                                        <select class="form-control" name="model" v-model="vehicleFilters.model" ref="modelField">
                                                            <option value="">{{ localeLang.FILTER.MODEL_PLACEHOLDER }}</option>
                                                            <option v-for="model in vehicleModels" :value="model.slug">{{ model.name }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <div class="input-col">
                                                    <div class="select-outer">
                                                        <select class="form-control select-2-field" v-model="vehicleFilters.badge" ref="badgeField">
                                                            <option value="">{{ localeLang.FILTER.BADGE_PLACEHOLDER }}</option>
                                                            <option v-for="badge in vehicleBadges" :value="badge.id">{{ badge.name }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <div class="input-col">
                                                    <div class="select-outer">
                                                        <select class="form-control select-2-field" v-model="vehicleFilters.series" ref="seriesField">
                                                            <option value="">{{ localeLang.FILTER.SERIES_PLACEHOLDER }}</option>
                                                            <option v-for="series in vehicleSeries" :value="series.id">{{ series.name }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <h3>{{ localeLang.FILTER.HEADING_PRICE }}</h3>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <div class="input-col">
                                                    <div class="select-outer">
                                                        <select class="form-control select-2-field" name="min_price" v-model="vehicleFilters.min_price" ref="minPriceField">
                                                            <option value="">{{ localeLang.FILTER.MIN_PRICE_PLACEHOLDER }}</option>
                                                            <option v-for="minPriceList in vehicleMinPriceLists" :value="minPriceList.value" :disabled="minPriceList.disabled">{{ minPriceList.display }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <div class="input-col">
                                                    <div class="select-outer">
                                                        <select class="form-control select-2-field" name="max_price" v-model="vehicleFilters.max_price" ref="maxPriceField">
                                                            <option value="">{{ localeLang.FILTER.MAX_PRICE_PLACEHOLDER }}</option>
                                                            <option v-for="maxPriceList in vehicleMaxPriceLists" :value="maxPriceList.value" :disabled="maxPriceList.disabled">{{ maxPriceList.display }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <h3>{{ localeLang.FILTER.HEADING_LOCATION }}</h3>
                                    <div class="form-group">
                                        <div class="input-col">
                                            <vue-google-autocomplete
                                                ref="addressField"
                                                name="address"
                                                id="map"
                                                classname="form-control"
                                                :placeholder="localeLang.FILTER.LOCATION_PLACEHOLDER"
                                                v-on:placechanged="filterByAddress"
                                                :country="['in', 'kh']"
                                                v-model="vehicleFilters.address"
                                            >
                                            </vue-google-autocomplete>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="input-col">
                                            <div class="select-outer">
                                                <select class="form-control select-2-field" v-model="vehicleFilters.distance" ref="distanceField" autocomplete="off">
                                                    <option value="">{{ localeLang.FILTER.DISTANCE_PLACEHOLDER }}</option>
                                                    <option value="25">25 {{ localeLang.KM }}</option>
                                                    <option value="50">50 {{ localeLang.KM }}</option>
                                                    <option value="100">100 {{ localeLang.KM }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <h3>{{ localeLang.FILTER.HEADING_SPECIFICATIONS }}</h3>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <div class="input-col">
                                                    <div class="select-outer">
                                                        <select class="form-control select-2-field" v-model="vehicleFilters.body_type" ref="bodyTypeField">
                                                            <option value="">{{ localeLang.FILTER.BODY_TYPE_PLACEHOLDER }}</option>
                                                            <option v-for="bodyType in bodyTypes" :value="bodyType.slug">{{ bodyType.name }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <div class="input-col">
                                                    <div class="select-outer">
                                                        <select class="form-control select-2-field" v-model="vehicleFilters.drive_type" ref="driveTypeField">
                                                            <option value="">{{ localeLang.FILTER.DRIVE_TYPE_PLACEHOLDER }}</option>
                                                            <option v-for="driveType in driveTypes" :value="driveType.id">{{ driveType.name }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <div class="input-col">
                                                    <div class="select-outer">
                                                        <select class="form-control select-2-field" v-model="vehicleFilters.fuel_type" ref="fuelTypeField">
                                                            <option value="">{{ localeLang.FILTER.FUEL_TYPE_PLACEHOLDER }}</option>
                                                            <option v-for="fuelType in fuelTypes" :value="fuelType.slug">{{ fuelType.name | capitalize | str_limit(20) }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <div class="input-col">
                                                    <div class="select-outer">
                                                        <select class="form-control select-2-field" v-model="vehicleFilters.fuel_economy" ref="fueleconomyField">
                                                            <option value="">{{ localeLang.FILTER.FUEL_ECONOMIES.TITLE }}</option>
                                                            <option v-for="(fuelEconomy, index) in localeLang.FILTER.FUEL_ECONOMIES.OPTIONS" :value="index">{{ fuelEconomy }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <div class="input-col">
                                                    <div class="select-outer">
                                                        <select class="form-control select-2-field" v-model="vehicleFilters.transmission" ref="transmissionField">
                                                            <option value="">{{ localeLang.FILTER.TRANSMISSION_PLACEHOLDER }}</option>
                                                            <option v-for="transmission in transmissions" :value="transmission.id">{{ transmission.name }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <div class="input-col">
                                                    <div class="select-outer">
                                                        <select class="form-control select-2-field" v-model="vehicleFilters.cylinder" ref="cylinderField">
                                                            <option value="">{{ localeLang.FILTER.CYLINDER_PLACEHOLDER }}</option>
                                                            <option v-for="index in 12" :value="index">{{ index }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <div class="select-outer">
                                                    <select class="form-control select-2-field" v-model="vehicleFilters.interior_color" ref="intcolorField">
                                                        <option value="">{{ localeLang.FILTER.INTERIOR_COLOR_PLACEHOLDER }}</option>
                                                        <option v-for="color in colors" :value="color.id">{{ color.name }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <div class="select-outer">
                                                    <select class="form-control select-2-field" v-model="vehicleFilters.exterior_color" ref="extcolorField">
                                                        <option value="">{{ localeLang.FILTER.EXTERIOR_COLOR_PLACEHOLDER }}</option>
                                                        <option v-for="color in colors" :value="color.id">{{ color.name }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <div class="select-outer">
                                                    <select class="form-control select-2-field" name="features[]" v-model="vehicleFilters.features" ref="featuresField">
                                                        <option value="">{{ localeLang.FILTER.FEATURES_PLACEHOLDER }}</option>
                                                        <option v-for="feature in features" :value="feature.id">{{ feature.name }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <div class="select-outer">
                                                    <select class="form-control select-2-field" v-model="vehicleFilters.lifestyle" ref="lifestyleField">
                                                        <option value="">{{ localeLang.FILTER.LIFESTYLE_PLACEHOLDER }}</option>
                                                        <option v-for="lifestyle in lifestyles" :value="lifestyle.id">{{ lifestyle.name }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <div class="select-outer">
                                                    <select class="form-control select-2-field" v-model="vehicleFilters.min_year" ref="minYearField">
                                                        <option value="">{{ localeLang.FILTER.MIN_YEAR_PLACEHOLDER }}</option>
                                                        <option v-for="minYear in yearsRange(1900)" :value="minYear.id" :disabled="((vehicleFilters.max_year !== '') && vehicleFilters.max_year < minYear.id)">{{ minYear.name }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <div class="select-outer">
                                                    <select class="form-control select-2-field" v-model="vehicleFilters.max_year" ref="maxYearField">
                                                        <option value="">{{ localeLang.FILTER.MAX_YEAR_PLACEHOLDER }}</option>
                                                        <option v-for="maxYear in yearsRange(1900)" :value="maxYear.id" :disabled="((vehicleFilters.min_year !== '') && (vehicleFilters.min_year > maxYear.id))">{{ maxYear.name }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <div class="select-outer">
                                                    <select class="form-control select-2-field" v-model="vehicleFilters.min_engine_size" ref="minEngineField">
                                                        <option value="">{{ localeLang.FILTER.MIN_ENGINE_SIZE_PLACEHOLDER }}</option>
                                                        <option v-for="minCapacity in vehicleMinCapacity" :value="minCapacity.value" :disabled="minCapacity.disabled">{{ minCapacity.display }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <div class="select-outer">
                                                    <select class="form-control select-2-field" v-model="vehicleFilters.max_engine_size" ref="maxEngineField">
                                                        <option value="">{{ localeLang.FILTER.MAX_ENGINE_SIZE_PLACEHOLDER }}</option>
                                                        <option v-for="maxCapacity in vehicleMaxCapacity" :value="maxCapacity.value" :disabled="maxCapacity.disabled">{{ maxCapacity.display }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <div class="select-outer">
                                                    <select class="form-control select-2-field" v-model="vehicleFilters.min_seats" ref="minSeatField">
                                                        <option value="">{{ localeLang.FILTER.MIN_SEAT_PLACEHOLDER }}</option>
                                                        <option v-for="minSeat in vehicleMinSeats" :value="minSeat.value" :disabled="minSeat.disabled">{{ minSeat.display }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <div class="select-outer">
                                                    <select class="form-control select-2-field" v-model="vehicleFilters.max_seats" ref="maxSeatField">
                                                        <option value="">{{ localeLang.FILTER.MAX_SEAT_PLACEHOLDER }}</option>
                                                        <option v-for="maxSeat in vehicleMaxSeats" :value="maxSeat.value" :disabled="maxSeat.disabled">{{ maxSeat.display }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <div class="select-outer select-2-field">
                                                    <select class="form-control" v-model="vehicleFilters.doors" ref="doorField">
                                                        <option value="">{{ localeLang.FILTER.DOOR_PLACEHOLDER }}</option>
                                                        <option v-for="index in 5" :value="index">{{ index }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <div class="select-outer">
                                                    <select class="form-control select-2-field" v-model="vehicleFilters.onsale" ref="onsaleField">
                                                        <option value="">{{ localeLang.FILTER.ONSALE_PLACEHOLDER }}</option>
                                                        <option value="yes" >{{ localeLang.YES }}</option>
                                                        <option value="no" >{{ localeLang.NO }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="advert-bottm" v-if="ads.bottom_ad">
                                    <div v-if="ads.bottom_ad.image_type === 1">
                                        <img :src="baseUrl+'/timthumb.php?src='+ads.bottom_ad.image_full_path+'&w=728&h=89&zc=1&q=95&s=1'" alt="" />
                                    </div>
                                    <div v-else>
                                        {{ ads.bottom_ad.image_scripts }}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-md-8 col-lg-9 right-bar-list pad-tb-30">
                        <!-- List component -->
                        <vehicle-list-component
                            :all-vehicles="allVehicles"
                            :loggedin-user="loggedinUser"
                            :comparing-vehicles="comparingVehicles"
                            @handle-toogle-favourite="toogleFavourite"
                            @handle-vehicle-inquiry="inquiryOnVehicle"
                            @handle-vehicle-paginate="vehiclePaginate"
                            @handle-add-to-compare="addToCompare"
                            :locale-lang="localeLang"
                        >
                        </vehicle-list-component>
                        <!-- End list component -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Custom Popup -->
        <vehicle-inquiry-modal-component
            :vehicleForInquiry="vehicleForInquiry"
            :locale-name="localeName"
            :locale-lang="localeLang"
            :loggedinUser="loggedinUser">
        </vehicle-inquiry-modal-component>
        <!-- Custom Popup -->
    </div>
</template>
<script>
    import 'select2/dist/css/select2.min.css';
    import bootbox from "bootbox";
    import select2 from 'select2';
    import VueGoogleAutocomplete from 'vue-google-autocomplete'
    import CommonMixin from '../../mixins/CommonMixin';
    import VehicleMixin from '../../mixins/VehicleMixin';
    export default {
        name:"vehicle-component",
        props:[
            "vehicles",
            "appliedFilters",
            "types",
            "makes",
            "bodyTypes",
            "fuelTypes",
            "dealerSeller",
            "features",
            "lifestyles",
            "transmissions",
            "colors",
            "driveTypes",
            "ads",
            "localeName",
            "localeLang",
            "loggedinUser"
        ],
        mixins: [CommonMixin, VehicleMixin],
        data() {
            return {
                allVehicles: [],
                comparingVehicles:[],
                vehicleForInquiry:"",
                filterUrl: "/vehicles",
                pageNumber: 1
            }
        },
        computed: {
            searchTitle: function () {
                let title = "";
                title += this.allVehicles.total;

                if(this.vehicleFilters.make){
                    title += " " + this.vehicleFilters.make;
                }
                if(this.vehicleFilters.model){
                    title += " " + this.vehicleFilters.model;
                }
                title += " " + ((this.allVehicles.total > 1) ? this.localeLang.MESSAGES.SEARCH_TITLE : this.localeLang.MESSAGES.SEARCH_TITLE);

                return title;
            },
            searchUrl: function() {
                let searchUrl = "/vehicles";
                if(this.vehicleFilters.make){
                    searchUrl += "/make-"+this.vehicleFilters.make;
                }
                if(this.vehicleFilters.model){
                    searchUrl += "/model-"+this.vehicleFilters.model;
                }
                if(this.vehicleFilters.body_type){
                    searchUrl += "/style-"+this.vehicleFilters.body_type;
                }
                if(this.vehicleFilters.type){
                    searchUrl += "/type-"+this.vehicleFilters.type;
                }
                if(this.vehicleFilters.added){
                    searchUrl += "/added-"+this.vehicleFilters.added;
                }
                if(this.vehicleFilters.fuel_type){
                    searchUrl += "/fule-"+this.vehicleFilters.fuel_type;
                }
                if(this.vehicleFilters.user){
                    searchUrl += "/user-"+this.vehicleFilters.user;
                }
                if(this.vehicleFilters.min_price && this.vehicleFilters.max_price){
                    searchUrl += "/price-"+this.vehicleFilters.min_price+"-"+this.vehicleFilters.max_price;
                }else if(this.vehicleFilters.min_price){
                    searchUrl += "/over-"+this.vehicleFilters.min_price;
                }else if(this.vehicleFilters.max_price){
                    searchUrl += "/under-"+this.vehicleFilters.max_price;
                }else if(this.vehicleFilters.onsale){
                    searchUrl += "/onsale-"+this.vehicleFilters.onsale;
                }

                return searchUrl;
            }
        },
        watch: {
            "vehicleFilters.make": function(n, o) {
                this.vehicleModels = [];
                if(this.vehicleFilters.make != ""){
                    this.getModels();
                }
            },
            "vehicleFilters.model": function(n, o) {
                this.vehicleBadges = [];

                if(this.vehicleFilters.model != ""){
                    this.getBadges();
                }
            },
            "vehicleFilters.badge": function(n, o) {
                this.vehicleSeries = [];
                if(this.vehicleFilters.badge != ""){
                    this.getSeries();
                }
            },
            "vehicleFilters.min_price": function(n, o) {
                this.vehicleMaxPriceLists = this.vehicleMaxPriceLists.map(priceList => ({
                    disabled: ((this.vehicleFilters.min_price !== "") && (priceList.value < this.vehicleFilters.min_price)),
                    value:priceList.value,
                    display:priceList.display
                }));
            },
            "vehicleFilters.max_price": function(n, o) {
                this.vehicleMinPriceLists = this.vehicleMinPriceLists.map(priceList => ({
                    disabled:  ((this.vehicleFilters.max_price !== "") && (priceList.value > this.vehicleFilters.max_price)),
                    value:priceList.value,
                    display:priceList.display
                }));
            },
            "vehicleFilters.min_engine_size": function(n, o) {
                this.vehicleMaxCapacity = this.vehicleMaxCapacity.map(capacityList => ({
                    disabled: ((this.vehicleFilters.min_engine_size !== "") && (capacityList.value <= this.vehicleFilters.min_engine_size)),
                    value:capacityList.value,
                    display:capacityList.display
                }));
            },
            "vehicleFilters.max_engine_size": function(n, o) {
                this.vehicleMinCapacity = this.vehicleMinCapacity.map(capacityList => ({
                    disabled:  ((this.vehicleFilters.max_engine_size !== "") && (capacityList.value >= this.vehicleFilters.max_engine_size)),
                    value:capacityList.value,
                    display:capacityList.display
                }));
            },
            "vehicleFilters.min_seats": function(n, o) {
                this.vehicleMaxSeats = this.vehicleMaxSeats.map(seatList => ({
                    disabled: ((this.vehicleFilters.min_seats !== "") && (seatList.value <= this.vehicleFilters.min_seats)),
                    value:seatList.value,
                    display:seatList.display
                }));
            },
            "vehicleFilters.max_seats": function(n, o) {
                this.vehicleMinSeats = this.vehicleMinSeats.map(seatList => ({
                    disabled:  ((this.vehicleFilters.max_seats !== "") && (seatList.value >= this.vehicleFilters.max_seats)),
                    value:seatList.value,
                    display:seatList.display
                }));
            },
            searchUrl: function(n, o){
                this.modifyHistoryState();
            },
            pageNumber: function(){
                this.getVehicles(this.pageNumber);
            }
        },
        methods: {
            modifyHistoryState() {
                if ("undefined" !== typeof history.pushState) {
                    history.pushState({}, null, this.searchUrl);
                } else {
                    window.location.assign(this.searchUrl);
                }
            },
            /* Get vehicles */
            async getVehicles(page = 1){

                this.loading = true;
                let url = "";
                url += this.searchUrl+'?page='+page;
                url += "&"+window.$.param(this.vehicleFilters);
                axios.get(url).then(response => {
                    this.allVehicles = response.data.data;
                    this.loading = false;
                    window.$(window).scrollTop(0);
                }).catch(error => {
                    this.loading = false;
                    flash(this.localeLang.MESSAGES.OOPS, 'error');
                    this.allVehicles = [];
                    window.$(window).scrollTop(0);
                    console.log(error);
                });
            },
            /* Get vehicles by keyword */
            vehiclePaginate(page) {
                this.getVehicles(page);
            },
            /* Get vehicles by keyword */
            filterByKeyword(e) {
                if ((e.which === 13) || (e.keyCode === 13)) {
                    e.preventDefault();
                    this.getVehicles();
                }
            },
            /* Get vehicles by address */
            filterByAddress: function (data, placeResultData, id) {
                this.vehicleFilters.address   = data.country;
                this.vehicleFilters.city      = data.locality;
                this.vehicleFilters.latitude  = data.latitude;
                this.vehicleFilters.longitude = data.longitude;
                /* Get vehicles */
                this.getVehicles();
            },
            /* Get vehicles by distance */
            filterByDistance(e) {
                if(!this.vehicleFilters.address && !this.vehicleFilters.latitude && !this.vehicleFilters.longitude){
                    flash(this.localeLang.MESSAGES.ENTER_ADDRESS, 'error');
                }
                /* Get vehicles */
                this.getVehicles();
            },
            /* remove vehicle to compare */
            removeCompare(vehicleId, vehicleKey){
                if(this.comparingVehicles.some(item => item.id === vehicleId)){
                    Vue.delete(this.comparingVehicles, vehicleKey);
                }
            },
            /* remove all vehicle to compare */
            removeAllCompare(){
                this.comparingVehicles = [];
            },
            /* add vehicle to compare */
            addToCompare(vehicleId, vehicleKey){
                let vehicle = this.allVehicles.data[vehicleKey];
                if(this.comparingVehicles.some(item => item.id === vehicleId)){
                    let key = this.comparingVehicles.findIndex(item => item.id === vehicleId);
                    Vue.delete(this.comparingVehicles, key);
                }else{
                    if(Object.keys(this.comparingVehicles).length >= 4){
                        bootbox.alert(this.localeLang.MESSAGES.COMPARE_LIMIT);
                    }else{
                        this.comparingVehicles.push({
                            id:vehicle.id,
                            title: vehicle.title,
                            image: vehicle.main_image ? vehicle.main_image.image_full_path : '',
                            slug: vehicle.slug,
                        });
                    }
                }
            },
            /*  toogle favourite */
            toogleFavourite(vehicleId, vehicleKey){
                axios.get('/api/vehicle/manage/'+vehicleId+'/toogle-favourite').then(response => {
                    this.loading = false;
                    if(response.data.status === 'success'){
                        let vehicle = this.allVehicles.data[vehicleKey];
                        vehicle.is_my_favourite = response.data.is_favourite;
                        Vue.set(this.allVehicles.data, vehicleKey, vehicle);
                        flash(response.data.message, response.data.status);
                    }else if(response.data.status === 'warning'){
                        bootbox.alert(response.data.message);
                    }else{
                        flash(response.data.message, response.data.status);
                    }
                }).catch(error => {
                    flash(this.localeLang.MESSAGES.OOPS, 'error');
                    this.loading = false;
                    console.log(error);
                });
            },
            /* enquire on vehicle */
            inquiryOnVehicle(vehicleId){
                let vm = this;
                window.$('.listing-inquiry-modal').modal({
                    keyboard: false,
                    backdrop: 'static'
                }).on('shown.bs.modal', function (e) {
                    vm.vehicleForInquiry = vehicleId;
                }).on('hidden.bs.modal', function (e) {
                    vm.vehicleForInquiry = "";
                });
            },
            /* Reset all filters */
            resetFilters() {
                for (let i = 0; i < Object.keys(this.vehicleFilters).length; i++) {
                    let index = Object.keys(this.vehicleFilters)[i];
                    this.vehicleFilters[index] = "";
                }
                this.vehicleFilters.address = "";
                window.$(this.$refs.typeField).val('').trigger('change');
                window.$(this.$refs.userField).val('').trigger('change');
                window.$(this.$refs.addedByField).val('').trigger('change');
                window.$(this.$refs.makeField).val('').trigger('change');
                window.$(this.$refs.modelField).val('').trigger('change');
                window.$(this.$refs.badgeField).val('').trigger('change');
                window.$(this.$refs.seriesField).val('').trigger('change');
                window.$(this.$refs.minPriceField).val('').trigger('change');
                window.$(this.$refs.maxPriceField).val('').trigger('change');
                window.$(this.$refs.bodyTypeField).val('').trigger('change');
                window.$(this.$refs.driveTypeField).val('').trigger('change');
                window.$(this.$refs.fuelTypeField).val('').trigger('change');
                window.$(this.$refs.fueleconomyField).val('').trigger('change');
                window.$(this.$refs.transmissionField).val('').trigger('change');
                window.$(this.$refs.cylinderField).val('').trigger('change');
                window.$(this.$refs.intcolorField).val('').trigger('change');
                window.$(this.$refs.extcolorField).val('').trigger('change');
                window.$(this.$refs.featuresField).val('').trigger('change');
                window.$(this.$refs.lifestyleField).val('').trigger('change');
                window.$(this.$refs.sortByField).val(1).trigger('change');
                window.$(this.$refs.distanceField).val('').trigger('change');
                window.$(this.$refs.doorField).val('').trigger('change');
                window.$(this.$refs.onsaleField).val('').trigger('change');
                window.$(this.$refs.minEngineField).val('').trigger('change');
                window.$(this.$refs.maxEngineField).val('').trigger('change');
                window.$(this.$refs.minSeatField).val('').trigger('change');
                window.$(this.$refs.maxSeatField).val('').trigger('change');
                window.$(this.$refs.minYearField).val('').trigger('change');
                window.$(this.$refs.maxYearField).val('').trigger('change');

                this.getVehicles();
            }
        },
        mounted(){
            let vm = this;
            vm.allVehicles = vm.vehicles;

            Vue.nextTick(() => {
                /* type select2 field */
                window.$(this.$refs.typeField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.type = this.value;
                    vm.getVehicles();
                });

                /* added by select2 field */
                window.$(this.$refs.addedByField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.added = this.value;
                    vm.getVehicles();
                });

                /* make select2 field */
                window.$(this.$refs.makeField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.make = this.value;
                    vm.vehicleFilters.model = "";
                    vm.vehicleFilters.badge = "";
                    vm.vehicleFilters.series = "";
                    vm.getVehicles();
                });
                /* model select2 field */
                window.$(this.$refs.modelField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.model = this.value;
                    vm.vehicleFilters.badge = "";
                    vm.vehicleFilters.series = "";
                    vm.getVehicles();
                });

                /* badge select2 field */
                window.$(this.$refs.badgeField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.badge = this.value;
                    vm.vehicleFilters.series = "";
                    vm.getVehicles();
                });

                /* series select2 field */
                window.$(this.$refs.seriesField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.series = this.value;
                    vm.getVehicles();
                });
                /* min price select2 field */
                window.$(this.$refs.minPriceField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.min_price = this.value;
                    vm.getVehicles();
                });
                /* max price select2 field */
                window.$(this.$refs.maxPriceField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.max_price = this.value;
                    vm.getVehicles();
                });
                /* body type select2 field */
                window.$(this.$refs.bodyTypeField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.body_type = this.value;
                    vm.getVehicles();
                });
                /* fuel type select2 field */
                window.$(this.$refs.fuelTypeField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.fuel_type = this.value;
                    vm.getVehicles();
                });

                /* fuel economy select2 field */
                window.$(this.$refs.fueleconomyField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.fuel_economy = this.value;
                    vm.getVehicles();
                });

                /* drive type select2 field */
                window.$(this.$refs.driveTypeField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.drive_type = this.value;
                    vm.getVehicles();
                });

                 /* drive type select2 field */
                window.$(this.$refs.userField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.user = this.value;
                    vm.getVehicles();
                });

                /* transmission select2 field */
                window.$(this.$refs.transmissionField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.transmission = this.value;
                    vm.getVehicles();
                });

                /* cylinder select2 field */
                window.$(this.$refs.cylinderField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.cylinder = this.value;
                    vm.getVehicles();
                });

                /* int color select2 field */
                window.$(this.$refs.intcolorField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.interior_color = this.value;
                    vm.getVehicles();
                });

                /* ext color select2 field */
                window.$(this.$refs.extcolorField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.exterior_color = this.value;
                    vm.getVehicles();
                });

                /* features color select2 field */
                window.$(this.$refs.featuresField).select2({
                    width:"100%",
                    //multiple: true,
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.features = this.value;
                    vm.getVehicles();
                });

                /* lifestyle color select2 field */
                window.$(this.$refs.lifestyleField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.lifestyle = this.value;
                    vm.getVehicles();
                });

                /* min year date field */
                window.$(this.$refs.minYearField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.min_year = this.value;
                    vm.getVehicles();
                });
                /* max year date field */
                window.$(this.$refs.maxYearField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.max_year = this.value;
                    vm.getVehicles();
                });

                /* min engine select2 field */
                window.$(this.$refs.minEngineField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.min_engine_size = this.value;
                    vm.getVehicles();
                });
                /* max engine select2 field */
                window.$(this.$refs.maxEngineField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.max_engine_size = this.value;
                    vm.getVehicles();
                });

                /* min seat select2 field */
                window.$(this.$refs.minSeatField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.min_seats = this.value;
                    vm.getVehicles();
                });
                /* max seat select2 field */
                window.$(this.$refs.maxSeatField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.max_seats = this.value;
                    vm.getVehicles();
                });

                /* seat select2 field */
                window.$(this.$refs.distanceField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.distance = this.value;
                    vm.filterByDistance();
                });

                /* doors select2 field */
                window.$(this.$refs.doorField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.doors = this.value;
                    vm.getVehicles();
                });

                /* on sale select2 field */
                window.$(this.$refs.onsaleField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.onsale = this.value;
                    vm.getVehicles();
                });

                /* sort select2 field */
                window.$(this.$refs.sortByField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.sort_by = this.value;
                    vm.getVehicles();
                });

                if(this.appliedFilters.hasOwnProperty('type')){
                    window.$(this.$refs.typeField).val(this.appliedFilters.type).trigger('change');
                    this.vehicleFilters.type = this.appliedFilters.type;
                }

                if(this.appliedFilters.hasOwnProperty('added')){
                    window.$(this.$refs.addedByField).val(this.appliedFilters.added).trigger('change');
                    this.vehicleFilters.added = this.appliedFilters.added;
                }

                if(this.appliedFilters.hasOwnProperty('onsale')){
                    window.$(this.$refs.onsaleField).val(this.appliedFilters.onsale).trigger('change');
                    this.vehicleFilters.onsale = this.appliedFilters.onsale;
                }

                if(this.appliedFilters.hasOwnProperty('make')){
                    window.$(this.$refs.makeField).val(this.appliedFilters.make).trigger('change');
                    this.vehicleFilters.make = this.appliedFilters.make;
                }

                if(this.appliedFilters.hasOwnProperty('model')){
                    window.$(this.$refs.modelField).val(this.appliedFilters.model).trigger('change');
                    this.vehicleFilters.model = this.appliedFilters.model;
                }

                if(this.appliedFilters.hasOwnProperty('min_price')){
                    window.$(this.$refs.minPriceField).val(this.appliedFilters.min_price).trigger('change');
                    this.vehicleFilters.min_price = this.appliedFilters.min_price;
                }

                if(this.appliedFilters.hasOwnProperty('max_price')){
                    window.$(this.$refs.maxPriceField).val(this.appliedFilters.max_price).trigger('change');
                    this.vehicleFilters.max_price = this.appliedFilters.max_price;
                }

                if(this.appliedFilters.hasOwnProperty('body_type')){
                    window.$(this.$refs.bodyTypeField).val(this.appliedFilters.body_type).trigger('change');
                    this.vehicleFilters.body_type = this.appliedFilters.body_type;
                }

                if(this.appliedFilters.hasOwnProperty('fuel_type')){
                    window.$(this.$refs.fuelTypeField).val(this.appliedFilters.fuel_type).trigger('change');
                    this.vehicleFilters.fuel_type = this.appliedFilters.fuel_type;
                }

            });

        },
        destroyed: function () {
            window.$(this.$refs.typeField).off().select2('destroy');
            window.$(this.$refs.addedByField).off().select2('destroy');
            window.$(this.$refs.userField).off().select2('destroy');
            window.$(this.$refs.makeField).off().select2('destroy');
            window.$(this.$refs.modelField).off().select2('destroy');
            window.$(this.$refs.badgeField).off().select2('destroy');
            window.$(this.$refs.seriesField).off().select2('destroy');
            window.$(this.$refs.minPriceField).off().select2('destroy');
            window.$(this.$refs.maxPriceField).off().select2('destroy');
            window.$(this.$refs.bodyTypeField).off().select2('destroy');
            window.$(this.$refs.driveTypeField).off().select2('destroy');
            window.$(this.$refs.fuelTypeField).off().select2('destroy');
            window.$(this.$refs.fueleconomyField).off().select2('destroy');
            window.$(this.$refs.transmissionField).off().select2('destroy');
            window.$(this.$refs.cylinderField).off().select2('destroy');
            window.$(this.$refs.intcolorField).off().select2('destroy');
            window.$(this.$refs.extcolorField).off().select2('destroy');
            window.$(this.$refs.featuresField).off().select2('destroy');
            window.$(this.$refs.lifestyleField).off().select2('destroy');
            window.$(this.$refs.sortByField).off().select2('destroy');
            window.$(this.$refs.distanceField).off().select2('destroy');
            window.$(this.$refs.minEngineField).off().select2('destroy');
            window.$(this.$refs.maxEngineField).off().select2('destroy');
            window.$(this.$refs.minSeatField).off().select2('destroy');
            window.$(this.$refs.maxSeatField).off().select2('destroy');
            window.$(this.$refs.doorField).off().select2('destroy');
            window.$(this.$refs.onsaleField).off().select2('destroy');
            window.$(this.$refs.minYearField).off().select2('destroy');
            window.$(this.$refs.maxYearField).off().select2('destroy');
        }
    }
</script>
